import React, { Fragment } from "react"
import { StaticImage, GatsbyImage, getImage } from "gatsby-plugin-image"
import { Link, useStaticQuery, graphql } from "gatsby"
import { useIntl, FormattedMessage } from "gatsby-plugin-intl"
import arrow1 from "../../images/arrow-1.png"
import arrow2 from "../../images/arrow-2.png"

export const query = graphql`
  query OthersCompanies {
    KGbio: contentfulOurStakeholdersImages(title: { eq: "KGbio" }) {
      image {
        gatsbyImageData
      }
    }
    Kalbe: contentfulOurStakeholdersImages(title: { eq: "Kalbe" }) {
      image {
        gatsbyImageData
      }
    }
    IGK: contentfulOurStakeholdersImages(title: { eq: "IGK" }) {
      image {
        gatsbyImageData
      }
    }
    KGM: contentfulOurStakeholdersImages(title: { eq: "KGM" }) {
      image {
        gatsbyImageData
      }
    }
    Genexine: contentfulOurStakeholdersImages(title: { eq: "Genexine" }) {
      image {
        gatsbyImageData
      }
    }
    GeneralAtlantic: contentfulOurStakeholdersImages(
      title: { eq: "GeneralAtlantic" }
    ) {
      image {
        gatsbyImageData
      }
    }
  }
`
const OthersCompanies = () => {
  const intl = useIntl()
  const { KGbio, Kalbe, IGK, Genexine, GeneralAtlantic, KGM } = useStaticQuery(
    query
  )

  return (
    <Fragment>
      <div className="row" style={{ flexWrap: "unset" }}>
        <div className="col-lg-4 col-md-4 col-sm-12">
          <div className="icon-bx-wraper center">
            <div className="icon-content">
              <a 
              target="_blank" 
              href={intl.locale === "cn" ? "https://www.kalbe.co.id/cn" : intl.locale === "id" ? "https://www.kalbe.co.id/id" : "https://www.kalbe.co.id/en"}
              // href="https://www.kalbe.co.id/en"
              >
                <GatsbyImage
                  image={getImage(Kalbe.image)}
                  alt="Kalbe-logo"
                  className="boxWidth"
                />
              </a>
            </div>
          </div>
        </div>
        <div className="col-lg-4 col-md-4 col-sm-12 m-t15 disableMargin">
          <div className="icon-bx-wraper center">
            <div className="icon-content">
              <a target="_blank" href="https://www.generalatlantic.com/">
                <GatsbyImage
                  image={getImage(GeneralAtlantic.image)}
                  alt="GeneralAtlantic-logo"
                  className="boxWidth"
                />
              </a>
            </div>
          </div>
        </div>
        <div className="col-lg-4 col-md-4 col-sm-12 m-t10 disableMargin">
          <div className="icon-bx-wraper center">
            <div className="icon-content">
              <a target="_blank" href="http://www.genexine.com/">
                <GatsbyImage
                  image={getImage(Genexine.image)}
                  alt="Genexine-logo"
                  className="boxWidth"
                />
              </a>
            </div>
          </div>
        </div>
      </div>
      <div className="row" style={{ flexWrap: "unset" }}>
        <div className="col-lg-12 col-md-12 col-sm-12">
          <div className="icon-bx-wraper center">
            <div className="icon-content">
              <img src={arrow1} alt="arrow1" />
            </div>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-lg-12 col-md-12 col-sm-12">
          <div className="icon-bx-wraper center">
            <div className="icon-content">
              <a target="_blank" href="https://www.kg-biologics.com/">
                <GatsbyImage
                  image={getImage(KGbio.image)}
                  alt="KGbio-logo"
                  className="boxWidth"
                />
              </a>
            </div>
          </div>
        </div>
      </div>
      <div className="row" style={{ flexWrap: "unset" }}>
        <div className="col-lg-12 col-md-12 col-sm-12">
          <div className="icon-bx-wraper center">
            <div className="icon-content">
              <img src={arrow2} alt="arrow2" />
            </div>
          </div>
        </div>
      </div>
      <div className="row" style={{ flexWrap: "unset" }}>
        <div className="col-lg-6 col-md-6 col-sm-12">
          <div className="icon-bx-wraper center">
            <div className="icon-content">
              <a target="_blank" href="http://www.innogene-kalbiotech.com/">
                <GatsbyImage
                  image={getImage(IGK.image)}
                  alt="IGK-logo"
                  className="boxWidth"
                />
              </a>
            </div>
          </div>
        </div>
        <div className="col-lg-6 col-md-6 col-sm-12">
          <div className="icon-bx-wraper center">
            <div className="icon-content">
              <a target="_blank" href="https://www.kalbio.co.id/">
                <GatsbyImage
                  image={getImage(KGM.image)}
                  alt="KGM-logo"
                  className="boxWidth"
                />
              </a>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  )
}

export default OthersCompanies
