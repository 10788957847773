import React, { Component } from "react"
import { Link } from "gatsby"
import Slider from "react-slick"
import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"
import { injectIntl } from "react-intl"

import SieDjohan from "../../images/about/our-leader-team/Sie Djohan.webp"
import NakkiranSaravanakumar from "../../images/about/our-leader-team/Nakkiran Saravanakumar.webp"
import JuliantoSetiady from "../../images/about/our-leader-team/Julianto Setiady.webp"

import JimmySusanto from "../../images/about/our-leader-team/Jimmy Susanto.webp"
import RavindraMore from "../../images/about/our-leader-team/Ravindra More.webp"
import ButetBennyManurung from "../../images/about/our-leader-team/Butet Benny Manurung.webp"

import LutfhiZarkasyi from "../../images/about/our-leader-team/Lutfhi Zarkasyi.webp"

import CatherineNatt from "../../images/about/our-leader-team/Catherine Natt.webp"

const content = [
  {
    image: SieDjohan,
    name: "Sie Djohan",
    title: "Kalbe Genexine Biologics CEO",
    jabatan: "CEO Kalbe Genexine Biologics",
    jabatanCN: "Kalbe Genexine Biologics 首席执行官",
    idDetail: 1,
  },
  {
    image: NakkiranSaravanakumar,
    name: "Nakkiran Saravanakumar",
    title: "President Director",
    jabatan: "Presiden Direktur",
    jabatanCN: "总裁",
    idDetail: 2,
  },
  {
    image: JuliantoSetiady,
    name: "Julianto Setiady",
    title: "R&D Director",
    jabatan: "Direktur R&D",
    jabatanCN: "研究和发展董事",
    idDetail: 3,
  },

  {
    image: JimmySusanto,
    name: "Jimmy Susanto",
    title: "Director",
    jabatan: "Direktur",
    jabatanCN: "董事",
    idDetail: 4,
  },
  {
    image: RavindraMore,
    name: "Ravindra More",
    title: "Plant GM",
    jabatan: "Plant GM",
    jabatanCN: "工厂总经理",
    idDetail: 5,
  },
  {
    image: LutfhiZarkasyi,
    name: "Lutfhi Zarkasyi",
    title: "Quality GM",
    jabatan: "Quality GM",
    jabatanCN: "质量总经理",
    idDetail: 6,
  },

  // {
  //   image: ButetBennyManurung,
  //   name: "Butet Benny Manurung",
  //   title: "Mfg Ops Excellence Head",
  //   jabatan: "Mfg Ops Excellence Head",
  //   jabatanCN: "制造运营卓越主管",
  //   idDetail: ,
  // },
  {
    image: CatherineNatt,
    name: "Catherine Natt",
    title: "Supply Chain GM",
    jabatan: "Supply Chain GM",
    jabatanCN: "供应链总经理",
    idDetail: 11,
  },

]

class TeamSliderCustom extends Component {
  render() {
    const settings = {
      // dots: true,
      infinite: true,
      speed: 500,
      slidesToShow: 3,
      slidesToScroll: 3,
      autoplay: false,
      responsive: [
        {
          breakpoint: 2560,
          settings: {
            slidesToShow: 3,
            slidesToScroll: 3,
          },
        },
        {
          breakpoint: 1440,
          settings: {
            slidesToShow: 3,
            slidesToScroll: 3,
          },
        },
        {
          breakpoint: 1200,
          settings: {
            slidesToShow: 3,
            slidesToScroll: 3,
          },
        },
        {
          breakpoint: 991,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 2,
          },
        },
        {
          breakpoint: 480,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
          },
        },
      ],
    }
    return (
      <>
        <Slider
          {...settings}
          className="dlab-team11-area dots-theme dots-long d-primary btn-style-2"
        >
          {content.map((item, id) => {
            return (
              <div
                key={id}
                className="item wow fadeInUp"
                data-wow-duration="2s"
                data-wow-delay="0.2s"
              >
                <div className="dlab-box dlab-team11">
                  <div className="dlab-media dlab-img-effect custom-size">
                    {item.idDetail === 7 || item.idDetail === 10 ? (
                      <img
                        src={item.image}
                        alt="team-img"
                        style={{ transform: "scaleX(-1)" }}
                      />
                    ) : (
                      <img src={item.image} alt="team-img" />
                    )}
                    {/* <img src={item.image} alt="team-img" /> */}
                  </div>
                  <div className="dlab-info">
                    <h4 className="dlab-title">{item.name}</h4>
                    {this.props.intl.locale === "en" ? (
                      <span className="dlab-position">{item.title}</span>
                    ) : this.props.intl.locale === "cn" ? (
                      <span className="dlab-position">{item.jabatanCN}</span>
                    ) : (
                        // <div
                        //   dangerouslySetInnerHTML={{
                        //     __html: intl.formatMessage({
                        //       id: "aboutus.aboutkgm.story1.d",
                        //     }),
                        //   }}
                        // ></div>
                      <span className="dlab-position">{item.jabatan}</span>
                    )}
                  </div>
                </div>
              </div>
            )
          })}
        </Slider>
      </>
    )
  }
}
export default injectIntl(TeamSliderCustom)
