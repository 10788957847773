import React, { Component } from "react"
import { Link } from "gatsby"
import Slider from "react-slick"
import responsive from "../slider-resonsive"
import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"
import { injectIntl } from "react-intl"

class ServicesSliderCustom extends Component {
  render() {
    const settings = {
      dots: false,
      infinite: true,
      speed: 500,
      slidesToShow: 3,
      slidesToScroll: 1,
      autoplay: false,
      responsive: [
        {
          breakpoint: 1200,
          settings: {
            slidesToShow: 2,
          },
        },
        {
          breakpoint: 600,
          settings: {
            slidesToShow: 2,
          },
        },
        {
          breakpoint: 480,
          settings: {
            slidesToShow: 1,
          },
        },
      ],
    }
    return (
      <>
        <Slider {...settings} className="about-ser-carousel btn-style-1">
          {this.props.data.map((item, id) => (
            <div
              key={id}
              className="item wow fadeInUp"
              data-wow-duration="2s"
              data-wow-delay="0.3s"
            >
              <div className="dlab-box service-media-bx recog-custom">
                <div className="dlab-media dlab-img-effect zoom">
                  <img src={item.image} alt="qualification-img" />
                </div>
                <div className="dlab-info text-center">
                  {this.props.intl.locale === "en" ? (
                    <h2 className="dlab-title">{item.serviceName}</h2>
                  ) : this.props.intl.locale === "cn" ? (
                    <h2 className="dlab-title">{item.namaCN}</h2>
                  ) : (
                    <h2
                      className="dlab-title"
                      dangerouslySetInnerHTML={{
                        __html: item.nama,
                      }}
                    >
                      
                    </h2>
                  )}

                  {this.props.intl.locale === "en" ? (
                    <p>{item.description}</p>
                  ) : this.props.intl.locale === "cn" ? (
                    <p>{item.deskripsiCN}</p>
                  ) : (
                    <p>{item.deskripsi}</p>
                  )}
                </div>
              </div>
            </div>
          ))}
        </Slider>
      </>
    )
  }
}
export default injectIntl(ServicesSliderCustom)
