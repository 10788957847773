import React, { Component } from "react"
import { Link } from "gatsby"
import Slider from "react-slick"
import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"
import { injectIntl } from "react-intl"

import YohanaNoviKristanti from "../../images/about/our-leader-team/Yohana Novi Kristanti.webp"
import AdityaFendyHeryanto from "../../images/about/our-leader-team/Aditya Fendy Heryanto.webp"
import YohanesAndiWijaya from "../../images/about/our-leader-team/Yohanes Andi Wijaya.webp"

import AntonSuwandoro from "../../images/about/our-leader-team/Anton Suwandoro.webp"
import HanslibrerySimanjuntak from "../../images/about/our-leader-team/Hanslibrery Simanjuntak.webp"
import KarelPramadiputraSanusi from "../../images/about/our-leader-team/Karel Pramadiputra Sanusi.webp"

import FahrizalDianNugraha from "../../images/about/our-leader-team/Fahrizal Dian Nugraha.webp"
import MujiHerliyanti from "../../images/about/our-leader-team/Muji Herliyanti.webp"
import AyuAsmoroNingrum from "../../images/about/our-leader-team/Ayu Asmoro Ningrum.webp"
import CindyCiputra from "../../images/about/our-leader-team/Cindy Ciputra.webp"

import YuniarKurnata from "../../images/about/our-leader-team/Yuniar Kurnata.webp"
import RadenRoroDiniNatalia from "../../images/about/our-leader-team/Raden Roro Dini Natalia.webp"
import YadilAndiBeddu from "../../images/about/our-leader-team/Yadil Andi Beddu.webp"

import EkoNugrohoWijayadi from "../../images/about/our-leader-team/Eko Nugroho Wijayadi.webp"
import StevenAmadeus from "../../images/about/our-leader-team/Steven Amadeus.webp"
import AgungWahyuSetyadi from "../../images/about/our-leader-team/Agung Wahyu Setyadi.webp"

import AntonHidayat from "../../images/about/our-leader-team/Anton Hidayat.webp"
import FathiAmin from "../../images/about/our-leader-team/Fathi Amin.webp"

import YunitaTirtasariSoenaryo from "../../images/about/our-leader-team/Yunita Tirtasari Soenaryo.webp"
import WhisnuThomasJulianto from "../../images/about/our-leader-team/Whisnu Thomas Julianto.webp"
import IinRuslan from "../../images/about/our-leader-team/Iin Ruslan.webp"

import VonnyWilianti from "../../images/about/our-leader-team/Vonny Wilianti.webp"
import RiniHayatiAmril from "../../images/about/our-leader-team/Vonny Wilianti.webp"
// import RiniHayatiAmril from "../../images/about/our-leader-team/Rini Hayati Amril.webp"

const content = [
  {
    image: YohanaNoviKristanti,
    name: "Yohana Novi Kristanti",
    title: "Production (Drug Products) Senior Manager",
    jabatan: "Senior Manajer Produksi (Drug Products)",
    jabatanCN: "生产（Drug Products）高级经理",
    idDetail: 7,
  },
  {
    image: AdityaFendyHeryanto,
    name: "Aditya Fendy Heryanto",
    title: "Production (Drug Substances) Manager",
    jabatan: "Manajer Produksi (Drug Substances)",
    jabatanCN: "生产经理（Drug Substances）",
    idDetail: 8,
  },
  {
    image: AntonSuwandoro,
    name: "Anton Suwandoro",
    title: "Engineering Manager",
    jabatan: "Manajer Engineering",
    jabatanCN: "工程经理",
    idDetail: 9,
  },

  {
    image: YohanaNoviKristanti,
    name: "Yohana Novi Kristanti",
    title: "Warehouse & PPIC Senior Manager",
    jabatan: "Senior Manajer Warehouse & PPIC",
    jabatanCN: "仓库和生产计划库存控制高级经理",
    idDetail: 10,
  },
  {
    image: RadenRoroDiniNatalia,
    name: "Raden Roro Dini Natalia",
    title: "Purchasing Manager",
    jabatan: "Manajer Purchasing",
    jabatanCN: "采购经理",
    idDetail: 12,
  },
  {
    image: FahrizalDianNugraha,
    name: "Fahrizal Dian Nugraha",
    title: "QC Senior Manager (Microbiology, TICO, Project, Compliance and Data Integrity)",
    jabatan: "Senior Manajer QC (Mikrobiologi, TICO, Proyek, Kepatuhan dan Integritas Data)",
    jabatanCN: "质量控制高级经理（Microbiology, TICO, Project, Compliance and Data Integrity）",
    idDetail: 13,
  },

  {
    image: MujiHerliyanti,
    name: "Muji Herliyanti",
    title: "QC Manager (RM, PM, IM, FG, ERP & System)",
    jabatan: "Manajer QC (RM, PM, IM, FG, ERP & Sistem)",
    jabatanCN: "质量控制经理（RM, PM, IM, FG, ERP & System）",
    idDetail: 14,
  },
  {
    image: AyuAsmoroNingrum,
    name: "Ayu Asmoro Ningrum",
    title: "QA Manager",
    jabatan: "Manajer QA",
    jabatanCN: "质量保证经理",
    idDetail: 15,
  },
  {
    image: CindyCiputra,
    name: "Cindy Ciputra",
    title: "QSC Manager",
    jabatan: "Manajer QSC",
    jabatanCN: "质量体系与合规经理",
    idDetail: 16,
  },

  {
    image: YuniarKurnata,
    name: "Yuniar Kurnata",
    title: "FA Manager",
    jabatan: "Manajer FA",
    jabatanCN: "财务经理",
    idDetail: 17,
  },
  {
    image: WhisnuThomasJulianto,
    name: "Whisnu Thomas Julianto",
    title: "HRGA Head",
    jabatan: "Kepala HRGA",
    jabatanCN: "人力资源与总务主管",
    idDetail: 19,
  },
  {
    image: StevenAmadeus,
    name: "Steven Amadeus",
    title: "IT Manager",
    jabatan: "Manajer IT",
    jabatanCN: "信息技术经理",
    idDetail: 20,
  },
  
  {
    image: YunitaTirtasariSoenaryo,
    name: "Yunita Tirtasari Soenaryo",
    title: "R&D Manager",
    jabatan: "Manajer R&D",
    jabatanCN: "研究和发展经理",
    idDetail: 18,
  },
  {
    image: KarelPramadiputraSanusi,
    name: "Karel Pramadiputra Sanusi",
    title: "Project Manager",
    jabatan: "Manajer Project",
    jabatanCN: "项目经理",
    idDetail: 21,
  },
  
  // {
  //   image: EkoNugrohoWijayadi,
  //   name: "Eko Nugroho Wijayadi",
  //   title: "HSSE & GA Manager",
  //   jabatan: "Manajer HSSE & GA",
  //   jabatanCN: "健康安全安保与环境及总务经理",
  //   idDetail: ,
  // },

  // {
  //   image: RiniHayatiAmril,
  //   name: "Rini Hayati Amril",
  //   title: "RA Senior Manager (Affiliates)",
  //   jabatan: "Senior Manajer RA (Affiliates)",
  //   jabatanCN: "监管事务高级经理（附属机构）",
  //   idDetail: 22,
  // },
  {
    image: FathiAmin,
    name: "Fathi Amin",
    title: "RA Manager (Affiliates)",
    jabatan: "Manajer RA (Affiliates)",
    jabatanCN: "监管事务经理（附属机构）",
    idDetail: 23,
  },
  {
    image: VonnyWilianti,
    name: "Vonny Wilianti",
    title: "BD & L Senior Manager (Affiliates)",
    jabatan: "Senior Manajer BD & L (Affiliates)",
    jabatanCN: "BD & L 高级经理（附属机构）",
    idDetail: 24,
  },
]

class TeamSliderCustom2 extends Component {
  render() {
    const settings = {
      // dots: true,
      infinite: true,
      speed: 500,
      slidesToShow: 3,
      slidesToScroll: 3,
      autoplay: false,
      responsive: [
        {
          breakpoint: 2560,
          settings: {
            slidesToShow: 3,
            slidesToScroll: 3,
          },
        },
        {
          breakpoint: 1440,
          settings: {
            slidesToShow: 3,
            slidesToScroll: 3,
          },
        },
        {
          breakpoint: 1200,
          settings: {
            slidesToShow: 3,
            slidesToScroll: 3,
          },
        },
        {
          breakpoint: 991,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 2,
          },
        },
        {
          breakpoint: 480,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
          },
        },
      ],
    }
    return (
      <>
        <Slider
          {...settings}
          className="dlab-team11-area dots-theme dots-long d-primary btn-style-2"
        >
          {content.map((item, id) => {
            return (
              <div
                key={id}
                className="item wow fadeInUp"
                data-wow-duration="2s"
                data-wow-delay="0.2s"
              >
                <div className="dlab-box dlab-team11">
                  <div className="dlab-media dlab-img-effect custom-size">
                    {item.idDetail === 7 || item.idDetail === 10 ? (
                      <img
                        src={item.image}
                        alt="team-img"
                        style={{ transform: "scaleX(-1)" }}
                      />
                    ) : (
                      <img src={item.image} alt="team-img" />
                    )}
                    {/* <img src={item.image} alt="team-img" /> */}
                  </div>
                  <div className="dlab-info">
                    <h4 className="dlab-title">{item.name}</h4>
                    {this.props.intl.locale === "en" ? (
                      <span className="dlab-position">{item.title}</span>
                    ) : this.props.intl.locale === "cn" ? (
                      <span className="dlab-position">{item.jabatanCN}</span>
                    ) : (
                        // <div
                        //   dangerouslySetInnerHTML={{
                        //     __html: intl.formatMessage({
                        //       id: "aboutus.aboutkgm.story1.d",
                        //     }),
                        //   }}
                        // ></div>
                      <span className="dlab-position">{item.jabatan}</span>
                    )}
                  </div>
                </div>
              </div>
            )
          })}
        </Slider>
      </>
    )
  }
}
export default injectIntl(TeamSliderCustom2)
