import React, { useState, Component } from "react"
import { useIntl, FormattedMessage } from "gatsby-plugin-intl"
import { injectIntl } from "react-intl"
import { Link, useStaticQuery, graphql } from "gatsby"
import Header from "../layouts/header"
import Footer from "../components/custom-components/FooterCream"
import HomeSliderCustom from "../components/custom-components/homeslider-custom"
import {
  homeSliderContent2,
  servicesContentCustom1,
  servicesContentCustom2,
} from "../components/SliderContent"
import ServicesSliderCustom from "../components/custom-components/serviceslider-custom"
import ServicesSliderCustom2 from "../components/custom-components/serviceslider-custom2"
import ProjectSlider2 from "../components/projectSlider2"
import OthersCompanies from "../components/custom-components/OthersCompanies"
import { StaticImage, GatsbyImage, getImage } from "gatsby-plugin-image"

import "../css/plugins.css"
import "../css/style.css"
import "../css/skin/skin-1.css"
import "../css/templete.min.css"
import "../css/custom-css/custom-about.css"

import ScrollToTop from "../components/scrollToTop"
import pic1 from "../images/about/about-kalbio-1.webp"
import pic2 from "../images/about/about-kalbio-2.webp"
import TeamSliderCustom from "../components/custom-components/teamslide-custom"
import TeamSliderCustom2 from "../components/custom-components/teamslide-custom2"
import FloatingBtn from "../components/custom-components/floatingBtn"
import ModalVideo from "react-modal-video"
import Seo from "../components/seo"

export const query = graphql`
  query About {
    mission: contentfulAboutKalbioImages(title: { eq: "mission" }) {
      image {
        gatsbyImageData
      }
    }
    vision: contentfulAboutKalbioImages(title: { eq: "vision" }) {
      image {
        gatsbyImageData
      }
    }
    KGbio: contentfulOurStakeholdersImages(title: { eq: "KGbio" }) {
      image {
        gatsbyImageData
      }
    }
    Kalbe: contentfulOurStakeholdersImages(title: { eq: "Kalbe" }) {
      image {
        gatsbyImageData
      }
    }
    IGK: contentfulOurStakeholdersImages(title: { eq: "IGK" }) {
      image {
        gatsbyImageData
      }
    }
    one: contentfulAboutKalbioImages(title: { eq: "one" }) {
      image {
        gatsbyImageData
      }
    }
    two: contentfulAboutKalbioImages(title: { eq: "two" }) {
      image {
        gatsbyImageData
      }
    }
    three: contentfulAboutKalbioImages(title: { eq: "three" }) {
      image {
        gatsbyImageData
      }
    }
    four: contentfulAboutKalbioImages(title: { eq: "four" }) {
      image {
        gatsbyImageData
      }
    }
    five: contentfulAboutKalbioImages(title: { eq: "five" }) {
      image {
        gatsbyImageData
      }
    }
  }
`

const Index = () => {
  const intl = useIntl()
  const [isOpen, setOpen] = useState(false)

  const {
    one,
    two,
    three,
    four,
    five,
    mission,
    vision,
    KGbio,
    Kalbe,
    IGK,
  } = useStaticQuery(query)

  return (
    <div className="skin-1">
      <Seo title={intl.formatMessage({ id: "header.aboutus" })} />

      <Header aboutPage />
      <FloatingBtn />

      <HomeSliderCustom data={homeSliderContent2} />

      <div id="kalbio" className="page-content bg-white">
        <div className="section-full content-inner bg-white">
          <div className="container" style={{ marginTop: "3rem" }}>
            <h2 className="title-abt">
              {intl.formatMessage({ id: "aboutus.aboutkgm.title" })}
            </h2>
            <p className="abt-text text-center responsiveText">
              {intl.formatMessage({
                id: "aboutus.aboutkgm.desc",
              })}
            </p>
            <h2 className="title-abt-2 text-center">
              {intl.formatMessage({
                id: "aboutus.aboutkgm.kgbiogroupbusinesslines.title",
              })}
            </h2>

            {/* business lines */}

            <div className="section-full bg-white content-inner-jsv">
              <div className="container">
                <div className="row">
                  <div className="col">
                    <div className="section-content box-sort-in button-example p-b0">
                      <div
                        className={
                          "row justify-content-center" +
                          (intl.locale === "id" ? " font-italic" : "")
                        }
                      >
                        <div className="col">
                          <div className="blog-post blog-md clearfix border-1 br-col-b1 radius-sm vision custom-box">
                            <div className="dlab-post-info vision abt-info">
                              <div className="custom-desc">
                                <p
                                  className="p-t15 text-white custom-para custom-line-height "
                                  dangerouslySetInnerHTML={{
                                    __html: intl.formatMessage({
                                      id:
                                        "aboutus.aboutkgm.kgbiogroupbusinesslines.desc.one",
                                    }),
                                  }}
                                ></p>
                              </div>

                              <div className="custom-icon-number icon-one">
                                <GatsbyImage
                                  image={getImage(one.image)}
                                  alt="one-img"
                                />
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className="col">
                          <div className="blog-post blog-md clearfix border-1 br-col-b1 radius-sm mission custom-box">
                            <div className="dlab-post-info mission abt-info">
                              <div className="custom-desc">
                                <p
                                  className="p-t15 text-white custom-para custom-line-height"
                                  dangerouslySetInnerHTML={{
                                    __html: intl.formatMessage({
                                      id:
                                        "aboutus.aboutkgm.kgbiogroupbusinesslines.desc.two",
                                    }),
                                  }}
                                ></p>
                              </div>
                              <div className="custom-icon-number">
                                <GatsbyImage
                                  image={getImage(two.image)}
                                  alt="two-img"
                                />
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className="col">
                          <div className="blog-post blog-md clearfix border-1 br-col-b1 radius-sm vision custom-box">
                            <div className="dlab-post-info vision abt-info">
                              <div className="custom-desc">
                                <p
                                  className="p-t15 text-white custom-para custom-line-height"
                                  dangerouslySetInnerHTML={{
                                    __html: intl.formatMessage({
                                      id:
                                        "aboutus.aboutkgm.kgbiogroupbusinesslines.desc.three",
                                    }),
                                  }}
                                ></p>
                              </div>
                              <div className="custom-icon-number">
                                <GatsbyImage
                                  image={getImage(three.image)}
                                  alt="three-img"
                                />
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className="col">
                          <div className="blog-post blog-md clearfix border-1 br-col-b1 radius-sm vision custom-box">
                            <div className="dlab-post-info vision abt-info">
                              <div className="custom-desc">
                                <p
                                  className="p-t15 text-white custom-para custom-line-height"
                                  dangerouslySetInnerHTML={{
                                    __html: intl.formatMessage({
                                      id:
                                        "aboutus.aboutkgm.kgbiogroupbusinesslines.desc.four",
                                    }),
                                  }}
                                ></p>
                              </div>
                              <div className="custom-icon-number icon-four">
                                <GatsbyImage
                                  image={getImage(four.image)}
                                  alt="four-img"
                                />
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className="col">
                          <div className="blog-post blog-md clearfix border-1 br-col-b1 radius-sm mission custom-box">
                            <div className="dlab-post-info mission abt-info">
                              <div className="custom-desc">
                                <p
                                  className="p-t15 text-white custom-para custom-line-height"
                                  dangerouslySetInnerHTML={{
                                    __html: intl.formatMessage({
                                      id:
                                        "aboutus.aboutkgm.kgbiogroupbusinesslines.desc.five",
                                    }),
                                  }}
                                >
                                </p>
                              </div>
                              <div className="custom-icon-number">
                                <GatsbyImage
                                  image={getImage(five.image)}
                                  alt="five-img"
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            {/* about 1 */}

            <div className="row align-items-center">
              <div
                className="col-lg-6 col-md-12 m-b30 wow fadeInLeft paddingText"
                data-wow-duration="2s"
                data-wow-delay="0.3s"
              >
                <div className="our-story">
                  <h2 className="story-custom">
                    {intl.formatMessage({
                      id: "aboutus.aboutkgm.story1.title",
                    })}
                  </h2>

                  <p className="abt-text">
                    <ol>
                      <li>
                        {intl.formatMessage({
                          id: "aboutus.aboutkgm.story1.a",
                        })}
                      </li>
                      <li>
                        {intl.formatMessage({
                          id: "aboutus.aboutkgm.story1.b",
                        })}
                      </li>
                      <li>
                        {intl.formatMessage({
                          id: "aboutus.aboutkgm.story1.c",
                        })}
                      </li>
                      <li>
                        <div
                          dangerouslySetInnerHTML={{
                            __html: intl.formatMessage({
                              id: "aboutus.aboutkgm.story1.d",
                            }),
                          }}
                        ></div>
                      </li>
                      <li>
                        <div
                          dangerouslySetInnerHTML={{
                            __html: intl.formatMessage({
                              id: "aboutus.aboutkgm.story1.e",
                            }),
                          }}
                        ></div>
                      </li>
                    </ol>
                  </p>
                </div>
              </div>
              <div
                className="col-lg-6 col-md-12 m-b30 our-story-thum wow fadeInRight"
                data-wow-duration="2s"
                data-wow-delay="0.3s"
              >
                <img src={pic1} alt="about-img"></img>
              </div>
            </div>

            {/* about 2 */}

            <div className="row align-items-center">
              <div
                className="col-lg-6 col-md-12 m-b30 our-story-thum wow fadeInRight"
                data-wow-duration="2s"
                data-wow-delay="0.3s"
              >
                <img src={pic2} alt="about-img"></img>
              </div>

              <div
                className="col-lg-6 col-md-12 m-b30 wow fadeInLeft"
                data-wow-duration="2s"
                data-wow-delay="0.3s"
              >
                <div className="our-story">
                  {/* <h2 className="title">About Kalbio Global Medika</h2> */}
                  <p
                    className="abt-text"
                    dangerouslySetInnerHTML={{
                      __html: intl.formatMessage({
                        id: "aboutus.aboutkgm.story2",
                      }),
                    }}
                  ></p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* visi misi */}

      <div className="section-full bg-white content-inner-jsv">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="section-content box-sort-in button-example p-b0">
                <div className="row">
                  <div className="col-md-6 col-lg-6">
                    <div className="blog-post blog-md clearfix border-1 br-col-b1 radius-sm vision">
                      <div className="dlab-post-info vision">
                        <div className="dlab-post-title">
                          <h4 className="post-title p-t20 p-b0  text-white">
                            {intl.formatMessage({
                              id: "aboutus.aboutkgm.vision.title",
                            })}
                          </h4>
                        </div>
                        <div className="custom-desc">
                          <p className="p-b20 p-r30 text-white custom-para">
                            {intl.formatMessage({
                              id: "aboutus.aboutkgm.vision.desc",
                            })}
                          </p>
                        </div>
                        <div className="custom-icon-vision">
                          <GatsbyImage
                            image={getImage(vision.image)}
                            alt="vision-img"
                          />
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="col-md-6 col-lg-6">
                    <div className="blog-post blog-md clearfix border-1 br-col-b1 radius-sm mission">
                      <div className="dlab-post-info mission">
                        <div className="dlab-post-title">
                          <h4 className="post-title p-t20 p-b0 text-white">
                            {intl.formatMessage({
                              id: "aboutus.aboutkgm.mission.title",
                            })}
                          </h4>
                        </div>
                        <div className="custom-desc">
                          <p className="p-b20 p-r30 text-white custom-para">
                            {intl.formatMessage({
                              id: "aboutus.aboutkgm.mission.desc",
                            })}
                          </p>
                        </div>
                        <div className="custom-icon-mission">
                          <GatsbyImage
                            image={getImage(mission.image)}
                            alt="mission-img"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* youtube */}

      <div className="content-block">
        <div className="section-full content-inner bg-gray video-section">
          <div className="container">
            <div className="section-content">
              <div className="col-lg-12 col-md-12 m-b30">
                <div className="video-bx">
                  <StaticImage
                    className="center-img-custom"
                    src="../images/about/thumbnail-about-us.webp"
                    alt="video-thumb-img"
                  />
                  <div className="video-play-icon">
                    <a
                      href="javascript:void(0)"
                      onClick={() => setOpen(true)}
                      className="popup-youtube video bg-youtube"
                    >
                      <i className="fa fa-play"></i>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {typeof window !== "undefined" && (
        <ModalVideo
          channel="youtube"
          allowFullScreen
          isOpen={isOpen}
          videoId="9MdNcQRUAm4"
          onClose={() => setOpen(false)}
        />
      )}

      {/* our partners */}

      <div id="our-partners" className="page-content frame-box m-b50">
        <div className="container container-custom">
          <div className="section-full content-inner-1 frame-project-area">
            <div className="section-head text-center">
              <h2 className="title">
                {intl.formatMessage({
                  id: "aboutus.ourpartners.title",
                })}
              </h2>
            </div>
            <ProjectSlider2 />
          </div>
        </div>
      </div>

      {/* qualifications */}

      <div id="qualifications" className="page-content bg-gray">
        <div className="content-block bg-white">
          <div className="section-full bg-gray content-inner about-carousel-ser">
            <div className="container">
              <div className="section-head text-center">
                <h2 className="title">
                  {intl.formatMessage({
                    id: "aboutus.qualifications.title",
                  })}
                </h2>
              </div>

              <ServicesSliderCustom
                slidesToShow={3}
                data={servicesContentCustom1}
              />
            </div>
          </div>
        </div>
      </div>

      {/* recognitions */}

      <div id="recognitions" className="page-content bg-white">
        <div className="content-block bg-white">
          <div className="section-full bg-white content-inner about-carousel-ser">
            <div className="container">
              <div className="section-head text-center">
                <h2 className="title">
                  {intl.formatMessage({
                    id: "aboutus.recognitions.title",
                  })}
                </h2>
              </div>

              <ServicesSliderCustom2
                slidesToShow={3}
                data={servicesContentCustom2}
              />
            </div>
          </div>
        </div>
      </div>

      {/* meet our leadership team  & department heads */}

      <div id="our-leader" className="page-content bg-gray">
        <div className="content-block area-frame bg-gray">
          <div className="container-custom-jsv">

            {/* meet our leadership team */}

            <div className="section-full content-inner-1 bg-gray lead-custom">
              <div className="container-custom-jsv2">
                <div className="section-head text-center">
                  <h2 className="title">
                    {intl.formatMessage({
                      id: "aboutus.leadershipteam.title",
                    })}
                  </h2>
                </div>
                <TeamSliderCustom />
              </div>
            </div>

            {/* meet our department heads */}

            <div id="our-depthead" className="section-full content-inner-1 bg-gray lead-custom">
              <div className="container-custom-jsv2">
                <div className="section-head text-center">
                  <h2 className="title">
                    {intl.formatMessage({
                      id: "aboutus.departmentheads.title",
                    })}
                  </h2>
                </div>
                <TeamSliderCustom2 />
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* our stakeholders */}

      <div id="our-stakeholders" className="page-content bg-gray">
        <div className="content-block">
          <div className="section-full content-inner-2 bg-white">
            <div className="container">
              <div className="section-head style2 text-center">
                <h2 className="title">
                  {intl.formatMessage({
                    id: "aboutus.ourstakeholders.title",
                  })}
                </h2>
              </div>

              <OthersCompanies />
            </div>
          </div>
        </div>
      </div>

      <Footer hideContactInfo />
      <ScrollToTop className="icon-up" />
    </div>
  )
}

export default Index
