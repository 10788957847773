import React, { Component } from "react"
import { Link } from "gatsby"
import { useIntl, FormattedMessage, IntlContextConsumer, changeLocale } from "gatsby-plugin-intl"
import { injectIntl } from "react-intl"
import Slider from "react-slick"
import responsive from "./slider-resonsive"
import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"

import bwt from "../images/about/our-partner/bwt.webp"
import cytiva from "../images/about/our-partner/cytiva.webp"
import ganlee from "../images/about/our-partner/gan-and-lee.webp"
import kalbe from "../images/about/our-partner/kalbe.webp"
import mabxience from "../images/about/our-partner/mAbxience.webp"
import korber from "../images/about/our-partner/korber.webp"
import kexing from "../images/about/our-partner/kexing-biopharm.webp"
import kalbeinternational from "../images/about/our-partner/kalbe-international.webp"
import genexine from "../images/about/our-partner/genexine.webp"
import sartorius from "../images/about/our-partner/sartorius.webp"
import generalatlantic from "../images/about/our-partner/general-atlantic.webp"

const content = [
  {
    image: kalbe,
    title: "Kalbe Farma",
    link: "https://www.kalbe.co.id/",
    linkId: "https://www.kalbe.co.id/id",
    linkCn: "https://www.kalbe.co.id/cn",
  },
  {
    image: kalbeinternational,
    title: "Kalbe International",
    link: "https://kalbeinternational.com/",
  },
  {
    image: kexing,
    title: "Shandong Kexing",
    link: "http://www.kexing.com/eindex.html",
    linkCn: "http://www.kexing.com/",
  },
  {
    image: ganlee,
    title: "Gan & Lee",
    link: "http://www.ganlee.com/en/",
    linkCn: "https://www.ganlee.com.cn/",
  },
  {
    image: mabxience,
    title: "mAbxience",
    link: "https://www.mabxience.com/",
  },
  {
    image: bwt,
    title: "BWT",
    link: "http://www.bwt-pharma.com/",
  },
  {
    image: korber,
    title: "Korber Pharma Inspection",
    link: "https://www.koerber-pharma.com/en/solutions/inspection",
    linkCn: "https://www.koerber-pharma.com/zh/",
  },
  {
    image: cytiva,
    title: "Cytiva",
    link: "https://www.cytivalifesciences.com/en/ca",
    linkCn: "https://www.cytivalifesciences.com.cn/zh/cn",
  },
]

class ProjectSlider2 extends Component {
  render() {
    const { formatMessage } = this.props.intl;
    const locale = this.props.intl.locale !== "en" ? `/${this.props.intl.locale}` : ""
    const settings = {
      dots: false,
      infinite: true,
      speed: 500,
      slidesToShow: 3,
      slidesToScroll: 1,
      autoplay: false,
      responsive: responsive(),
    }
    return (
      <>
        <Slider {...settings} className="btn-style-2">
          {content.map((item, id) => (
            <div
              key={id}
              className="item wow fadeInUp"
              data-wow-duration="2s"
              data-wow-delay="0.3s"
            >
              <div className="project-bx">
                <div className="media dlab-img-effect ">
                  <img src={item.image} alt="logo-img" />
                </div>
                <div className="info">
                  <a 
                  href={locale === "/cn" ? (item.linkCn ? item.linkCn : item.link) : locale === "/id" ? (item.linkId ? item.linkId : item.link) : item.link}
                  target="_blank" 
                  rel="noopener noreferrer"
                  >
                    <h4 className="title">{item.title}</h4>
                  </a>
                </div>
              </div>
            </div>
          ))}
        </Slider>
      </>
    )
  }
}
export default injectIntl(ProjectSlider2)
